import * as React from "react"
import { Layout, Menu, Breadcrumb, Divider, Typography } from 'antd';


import { MyHeader } from "../components/MyHeader.js"
import { MyFooter } from "../components/MyFooter.js"



export default function About() {
  return(
    <main>
      <Layout>
        <MyHeader>

        </MyHeader>

        <MyFooter>

        </MyFooter>
      </Layout>
    </main>
  )
}